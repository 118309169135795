@SBV_DECORATOR: ~':local(.sbvDecorator)';
@SBV_DECORATOR_CHILDREN: ~':local(.sbvDecoratorChildren)';
@SBV_DECORATOR_IMAGE_WRAPPER: ~':local(.sbvDecoratorImageWrapper)';
@SBV_DECORATOR_IMAGE: ~':local(.sbvDecoratorImage)';

@{SBV_DECORATOR} {
    display: flex;
    flex-direction: column;
}

@{SBV_DECORATOR_CHILDREN} {
    margin-top: 20px;
}

@{SBV_DECORATOR_IMAGE_WRAPPER} {
    flex: 1;
    padding-top: 30px;
}

@{SBV_DECORATOR_IMAGE} {
    width: 100%;
}
